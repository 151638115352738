<template>
  <div>
    <b-row>
      <b-col lg="6" />
      <b-col
        class="mb-1"
        lg="6"
      >
        <!-- button on right -->
        <form @submit.prevent="search_shipment(currentPage)">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                v-b-modal.modal-prevent-closing
                variant="outline-primary"
              >
                Filters
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="form.q"
              autofocus
              placeholder="Search by scan qrCode or reference number or phone"
              required="required"
            />
            <b-input-group-append>
              <b-button
                type="submit"
                variant="primary"
              >
                search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </form>
        <b-modal
          id="modal-prevent-closing"
          ref="my-modal"
          cancel-variant="outline-secondary"
          ok-title="Filter"
          size="lg"
          title="Filter Shipments"
          @ok="handleOk"
        >
          <form
            ref="form"
            @submit.stop.prevent="handleSubmit"
          >
            <b-row>
              <b-col md="6">
                <b-form-group
                  invalid-feedback="search by AWB"
                  label="By AWB"
                  label-for="awb"
                >
                  <b-form-tags
                    v-model="form.awb"
                    class="mb-2"
                    input-id="awb"
                    placeholder="Enter new awb separated by space, comma or semicolon"
                    remove-on-delete
                    separator=" ,;"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <h5>Range</h5>
                  <flat-pickr
                    v-model="form.rangeDate"
                    :config="{ mode: 'range'}"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="By Status"
                  label-for="status"
                >
                  <v-select
                    id="status"
                    v-model="form.status_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statuses"
                    :reduce="status => `${status.id}`"
                    label="name"
                    placeholder="Search by client no, name, contact name, phone or mobile..."
                  >
                    <!--                                        <template #option="{ name, code }">
                                                                <span> {{ code }}</span> -
                                                                <span> {{ name }}</span>
                                                            </template>-->
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loading"
          rounded="sm"
        >
          <b-card-code title="Shipments">
            <div class="float-left">
              <b-button
                v-if="rows.length !== 0"
                type="button"
                variant="primary"
              >
                <download-excel
                  :data="rows"
                  :fields="json_fields"
                  name="shipments.xls"
                >
                  Export
                  <DownloadIcon size="16" />
                </download-excel>
              </b-button>
              <b-button
                v-if="selected"
                v-ripple.400="'rgba(219,240,102,0.15)'"
                variant="outline-success"
                class="ml-1"
                @click="printShipments"
              >
                Print A4
              </b-button>
              <b-button
                v-if="selected"
                v-ripple.400="'rgba(219,240,102,0.15)'"
                variant="outline-primary"
                class="ml-1"
                @click="printMiniShipments"
              >
                Print A5
              </b-button>
            </div>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    class="d-inline-block"
                    placeholder="Search"
                    type="text"
                  />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :columns="columns"
              :pagination-options="{
                enabled: false,
                perPage:pageLength,
                dropdownAllowAll: true,
              }"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              @on-select-all="onSelectAll"
              @on-selected-rows-change="selectionChanged"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span
                  v-if="props.column.field === 'awb'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap"><router-link
                    :to="`/shipment/${props.row.id}/show`"
                  >{{ props.row.awb }}</router-link></span>
                </span>
                <span
                  v-else-if="props.column.field === 'created_at'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.created_at | formatDate }}</span>
                </span>
                <span
                  v-else-if="props.column.field === 'client'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.client.name }}</span>
                </span>
                <span
                  v-else-if="props.column.field === 'consignee'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.consignee.name }}</span>
                </span>
                <span
                  v-else-if="props.column.field === 'ref_no'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.ref_no }}</span>
                </span>
                <span
                  v-else-if="props.column.field === 'phone1'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{
                    props.row.phone1
                  }} <br> {{ props.row.phone2 ? props.row.phone2 : '' }}</span>
                </span>
                <span
                  v-else-if="props.column.field === 'service_type'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.service_type.title }}</span>
                </span>
                <span
                  v-else-if="props.column.field === 'status'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.status.name }}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown
                      no-caret
                      toggle-class="text-decoration-none"
                      variant="link"
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          class="text-body align-middle mr-25"
                          icon="MoreVerticalIcon"
                          size="16"
                        />
                      </template>
                      <b-dropdown-item :to="`shipment/${props.row.id}/edit`">
                        <feather-icon
                          class="mr-50"
                          icon="Edit2Icon"
                        />
                        <span>Update</span>
                      </b-dropdown-item>
                      <!--                      <b-dropdown-item @click="deleteItem(props.row, props.index)">
                        <feather-icon
                          class="mr-50"
                          icon="TrashIcon"
                        />
                        <span>Delete</span>
                      </b-dropdown-item>-->
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10','50','100', '500']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      align="right"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <select
                  id="__BVID__133"
                  v-model="pageLength"
                  class="mx-1 custom-select"
                  @change="pageLengthChanged"
                >
                  <option value="10">
                    10
                  </option>
                  <option value="50">
                    50
                  </option>
                  <option value="100">
                    100
                  </option>
                  <option value="500">
                    500
                  </option>
                </select>
                <span class="text-nowrap"> of {{ totalRowsCount }} entries </span>
              </div>
              <div class="demo-spacing-0 p-1 float-right">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRowsCount"
                  :per-page="pageLength"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="(value)=>handlePagination(value)"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </b-card-code>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { debounce } from 'lodash'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import axios from '@/libs/axios'
import { DownloadIcon } from 'vue-feather-icons'
import moment from 'moment-timezone'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    vSelect,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BOverlay,
    flatPickr,
    BFormTags,
    DownloadIcon,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      currentPage: 1,
      totalRowsCount: 0,
      dir: false,
      json_fields: {
        AWB: 'awb',
        'Created at': 'created_at',
        Pickup: 'pickup.pickup_no',
        Status: 'status.name',
        'Consignee Name': 'consignee_name',
        Phone1: 'phone1',
        Phone2: 'phone2',
        'Atten to': 'atten_to',
        City: 'city.title',
        Area: 'area',
        Address: 'address',
        'Charge Type': 'charge_type',
        'Charge on': 'charge_on.name',
        'Service Type': 'service_type.title',
        Remarks: 'remarks',
        Pieces: 'pieces',
        'Client Address': 'client_address.address',
        'Total Amount': 'total_amount',
        'Total Items Price': 'items_price',
        'Total Weights': 'total_weights',
        Description: 'description',
      },
      columns: [
        {
          label: 'AWB',
          field: 'awb',
        },
        {
          label: 'Created at',
          field: 'created_at',
        },
        {
          label: 'Pickup',
          field: 'pickup.pickup_no',
        },
        {
          label: 'Consignee',
          field: 'consignee_name',
        },
        {
          label: 'Phone',
          field: 'phone1',
        },
        {
          label: 'Remark',
          field: 'remarks',
        },
        {
          label: 'Service Type',
          field: 'service_type.title',
        },
        {
          label: 'Status',
          field: 'status.name',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      clients: [],
      statuses: [],
      form: {
        q: null,
        awb: [],
        client_id: null,
        rangeDate: null,
        status_id: null,
      },
      loading: false,
      selected: false,
      selected_rows: [],
      multi_status: null,
      function_used: '',
      filtersArr: [],
      header_export: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getStatues()
    this.filter_shipment(1)
  },
  methods: {
    searchClient(search, loading) {
      if (search) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      axios.post('/clients/search', {
        /* filters: [
              {field: 'client_no', 'operator': '=', value: search}
          ], */
        search: { value: search },
      }).then(res => {
        // eslint-disable-next-line no-param-reassign
        vm.clients = res.data.data
        loading(false)
      })
    }, 350),
    search_shipment(currentPage) {
      this.loading = true
      this.function_used = 'search_shipment'
      axios.post('/shipmentsOverAll/search', {
        /* filters: [
              {field: 'client_no', 'operator': '=', value: search}
          ], */
        search: { value: this.form.q },
        page: currentPage,
        offset: this.pageLength,
      }).then(res => {
        this.rows = res.data.data
        this.totalRowsCount = res.data.meta.total
        this.loading = false
      })
    },
    filter_shipment(currentPage) {
      this.function_used = 'filter_shipment'
      this.filtersArr = []
      if (this.form.awb.length !== 0) {
        this.filtersArr.push({ field: 'awb', operator: 'in', value: this.form.awb })
      }

      if (this.form.client_id) {
        this.filtersArr.push({ field: 'client_id', operator: '=', value: this.form.client_id.id })
      }
      if (this.form.rangeDate) {
        const rangeDates = this.form.rangeDate.split(' to ')
        this.filtersArr.push(
          { field: 'created_at', operator: '>=', value: rangeDates[0] },
          { field: 'created_at', operator: '<', value: rangeDates[1] },
        )
      }
      if (this.form.status_id) {
        this.filtersArr.push({ field: 'status_id', operator: '=', value: this.form.status_id })
      }
      if (this.filtersArr.length === 0) {
        const from = moment().format('YYYY-MM-DD')
        const to = moment(from).add(1, 'day').format('YYYY-MM-DD')
        this.filtersArr.push(
          { field: 'created_at', operator: '>=', value: from },
          { field: 'created_at', operator: '<', value: to },
        )
      }
      this.loading = true
      axios.post('/shipmentsOverAll/search', {
        filters: this.filtersArr,
        page: currentPage,
        offset: this.pageLength,
        // search: {"value": this.form.q}
      }).then(res => {
        this.rows = res.data.data
        this.totalRowsCount = res.data.meta.total
        this.loading = false
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'CheckIcon',
            variant: 'danger',
          },
        })
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      /* if (!this.checkFormValidity()) {
          return
      } */
      // Push the name to submitted names
      this.filter_shipment()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    onSelectAll(params) {
      this.selected = params.selected
      this.selected_rows = params.selectedRows
    },
    selectionChanged(params) {
      this.selected_rows = params.selectedRows
      this.selected = this.selected_rows.length !== 0
    },
    getStatues() {
      axios.get('/statuses/').then(resp => {
        this.statuses = resp.data.data
      })
    },
    printShipments() {
      const shipmentsArr = []
      this.selected_rows.map(item => shipmentsArr.push(item.id))
      const arrStr = encodeURIComponent(JSON.stringify(shipmentsArr))
      this.$router.push(`/shipment/print?shipments=${arrStr}`)
    },
    printMiniShipments() {
      const shipmentsArr = []
      this.selected_rows.map(item => shipmentsArr.push(item.id))
      const arrStr = encodeURIComponent(JSON.stringify(shipmentsArr))
      this.$router.push(`/shipment/miniPrint?shipments=${arrStr}`)
    },
    handlePagination(currentPage) {
      const type = this.function_used
      if (type === 'search_shipment') {
        this.search_shipment(currentPage)
      } else if (type === 'filter_shipment') {
        this.filter_shipment(currentPage)
      }
    },
    pageLengthChanged() {
      this.handlePagination(1)
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
@import 'src/@core/scss/vue/libs/vue-good-table.scss';
</style>
